.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  width: 300px;
  max-width: 80%;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.modal-header {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.separator {
  height: 2px;
  background: #f0f0f0;
  margin: 10px 0;
}

.close-btn {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.close-btn:hover {
  color: white;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-container p {
  margin: 0;
  font-size: 22px;
  padding-right: 10px;
}

.copy-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #aaa; /* Gray color for the icon */
  font-size: 20px;
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.copy-btn:hover {
  color: white; /* Blue color on hover */
}

.copy-success {
  color: white;
  margin-top: 10px;
}
