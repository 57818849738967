.letters-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 130px;
  height: 100vh;
  width: 100vw;
}

.letter {
  font-size: 13vw;
  font-weight: bold;
  color: var(--primary-color);
  line-height: 1;
  position: relative;
  cursor: crosshair;
  transition: opacity 2s ease-in-out, transform 2s ease-in-out;
}

.letter-text {
  font-size: 1.2vw;
  font-weight: 300;
  width: 500%;
  color: var(--secondary-color);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(190px);
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

@media (max-width: 1024px) {
  .letters-container {
    position: relative;
  }

  .letter {
    position: unset;
  }

  .letter-text {
    font-size: 0.9rem;
    width: 100vw;
    top: 45%;
  }

  .letter-text p {
    text-align: center !important;
  }
}

@media (max-width: 760px) {
  .letter-text {
    font-size: 12px;
    width: 100vw;
    top: 45%;
  }
}

.letter:hover .letter-text {
  opacity: 1;
  transform: translateX(-50%) translateY(0); /* Le texte remonte au survol */
}

.letter-text p {
  padding-top: 10px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  white-space: nowrap;
  text-align: center;
}

.letter:hover .letter-text p:nth-child(1) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.1s;
}

.letter:hover .letter-text p:nth-child(2) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.2s;
}

.letter:hover .letter-text p:nth-child(3) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.3s;
}

.letter:hover .letter-text p:nth-child(4) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.4s; /* Début d'animation après 0.4s */
}
