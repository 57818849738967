.header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  width: 100vw;
}

.logo img {
  cursor: pointer;
  width: 150px;
  height: auto;
}

.nav {
  display: flex;
  gap: 20px;
}

.nav a {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.3s ease;
  white-space: nowrap;
}

.nav a:hover {
  color: var(--hover-color);
}

@media (max-width: 1024px) {
  .nav a {
    font-size: 16px;
  }
  .nav {
    gap: 10px;
  }
  .logo img {
    width: 100px;
  }
}
