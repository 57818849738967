$animation-easing: cubic-bezier(0.46, 0.03, 0.52, 0.96);

body {
  background: #ffffff;
  font-family: "Allerta Stencil", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  flex-direction: column;
}

.c-planet {
  width: 150px;
  height: 150px;
  background: url("./earth.png") no-repeat center center;
  background-size: cover;
  border-radius: 100px;
  position: relative;
}

.c-orbit {
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  top: -75px;
  left: -75px;
  transform-origin: center;
  animation: rotate 2s linear infinite;
}

.c-plane {
  position: absolute;
  top: 0;
  left: 10%;
  width: 40px;
  height: 40px;
  background: url("./plane.png") no-repeat center center;
  background-size: contain;
  transform-origin: center center;
  animation: rotatePlane 3s linear infinite, float 2s ease-in-out infinite; // Ajout de l'effet de flottement
}

/* Effet de flottement de l'avion */
@keyframes float {
  0% {
    transform: translate(-50%, 0px) rotate(0deg);
  }
  50% {
    transform: translate(-50%, -10px) rotate(0deg); // Légère montée et descente
  }
  100% {
    transform: translate(-50%, 0px) rotate(0deg);
  }
}

/* Rotation autour de la planète */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Rotation de l'avion pour qu'il reste aligné à la trajectoire */
@keyframes rotatePlane {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cities {
  display: flex;
  left: 50px;
  right: 50px;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
}

.city {
  color: #000000;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

@media (max-width: 1024px) {
  .city {
    font-size: 12px;
  }
}
