.modalContainer {
  /* Webkit-based browsers (Chrome, Safari, Edge) */
  ::-webkit-scrollbar {
    width: 8px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.05);
  }

  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.05);
  }
}
