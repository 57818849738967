.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  transform: scale(1.15);
  filter: brightness(0.8);
}
.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow: hidden;
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.letters-container {
  z-index: 2;
}

.contact-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-size: calc(1rem + 3vw);
  font-family: "Yantramanav", sans-serif;
  opacity: 0;
  animation: fadeIn 5s forwards;
  white-space: normal;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  width: 90vw;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.contact-info p {
  margin: 10px 0;
}

@media screen and (max-width: 600px) {
  .modalContainer {
    top: 5vh;
    max-height: 85vh;
    width: 95%;
  }
}

@media screen and (max-height: 812px) and (min-width: 375px) {
  .modalContainer {
    top: 5vh;
    max-height: 80vh;
  }
}
